
import { useState } from 'react'
import {
    Grid,
    Stack,
    Typography,
    Button
} from '@mui/material'
import loginlanding from 'images/landing.svg'
import ButtonWithAlert from 'components/views/ButtonWithAlert'
import { Auth } from 'components/login/firebaseAuth'
import { useNavigate } from 'react-router-dom'
import Constant from 'route/url'


function VerifyEmail() {

    const navigate = useNavigate()

    return <Stack spacing={1} alignItems='center' sx={{ maxWidth: 400, width: "100%" }}>
        <Typography>
            We sent you an email to verify your email address
        </Typography>
        <Stack spacing={1} direction='row' sx={{ display: 'flex', width: "100%" }}>
            <ButtonWithAlert
                sx={{ flex: 1 }}
                variant='contained'
                color='secondary'
                apiCall={Auth.sendEmailVerification}
                successMessage='The Email has been sent'
            >
                Resend Email
            </ButtonWithAlert>
            <ButtonWithAlert
                sx={{ flex: 1 }}
                variant='contained'
                apiCall={Auth.checkEmailVerified}
                successMessage='The Email has been verified'
                onSuccess={() => navigate(Constant.URL.CONVERSATIONS)}
            >
                Next
            </ButtonWithAlert>
        </Stack>
    </Stack>
}


export default function VerifyEmailView() {
    return <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%", pb: 20 }}>

        <Grid item md={6} >
            <img src={loginlanding} alt='landing' style={{ width: 480 }} />
        </Grid>
        <Grid item md={6}>
            <VerifyEmail />
        </Grid>
    </Grid>

}