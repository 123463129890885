import { initializeApp } from "firebase/app";
import {
    getAuth,
    onAuthStateChanged,
    browserLocalPersistence,
    browserSessionPersistence,
    setPersistence,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    sendEmailVerification,
    deleteUser,
    signOut,
} from "firebase/auth"


const firebaseConfig = (window.location.hostname === 'insight.standpointapp.com') ? (
    {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY_PROD,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_PROD,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID_PROD,
        storageBucket: process.env.REACT_APP_FIREBASE_BUCKET_PROD,
        messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID_PROD,
        appId: process.env.REACT_APP_FIREBASE_APPID_PROD,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID_PROD
    }
) : (
    {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY_STAGING,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_STAGING,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID_STAGING,
        storageBucket: process.env.REACT_APP_FIREBASE_BUCKET_STAGING,
        messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID_STAGING,
        appId: process.env.REACT_APP_FIREBASE_APPID_STAGING,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID_STAGING
    }
)

const app = initializeApp(firebaseConfig)


function getFirebaseErrorMessage(error, onFailure) {
    var message = 'Oops something wrong happened!'
    console.log(error.code)
    switch (error.code) {
        case 'auth/email-already-exists':
            message = 'The provided email is already in use by an existing user. Each user must have a unique email.'
            break
        case 'auth/email-already-in-use':
            message = 'The provided email is already in use by an existing user. Each user must have a unique email.'
            break
        case 'auth/invalid-email':
            message = 'The provided value for the email user property is invalid. It must be a string email address.'
            break
        case 'auth/invalid-password':
            message = 'The provided value for the password user property is invalid. It must be a string with at least six characters.'
            break
        case 'auth/user-not-found':
            message = 'There is no existing user record corresponding to the provided identifier.'
            break
        default:
            message = 'Oops something wrong happened!'
    }
    onFailure(message)
}


class Auth {

    static getAuth() {
        return getAuth(app)
    }

    static checkEmailVerified({
        onSuccess = () => { return null },
        onFailure = () => { return null }
    }) {
        let auth = getAuth(app)
        auth.currentUser.reload().then(
            auth.currentUser.reload().then(() => {
                const user = auth.currentUser
                if (user && user.emailVerified) {
                    onSuccess()
                } else {
                    onFailure("The email has not been verified!")
                }
            })
        )
    }

    static AuthAPICall(callback) {
        let auth = getAuth(app)
        onAuthStateChanged(auth, async user => {
            if (user && user.emailVerified) {
                let token = await user.getIdToken()
                callback(token)
            }
        })
    }

    static sendEmailVerification({
        onSuccess = () => { return null },
        onFailure = () => { return null }
    }) {
        let auth = getAuth(app)
        onAuthStateChanged(auth, user => {
            console.log(user)
            if (user) {
                sendEmailVerification(user)
                onSuccess()
            } else {
                onFailure()
            }
        })
    }

    static createUserWithEmailAndPassword({
        email,
        password,
        onSuccess = () => { return null },
        onFailure = () => { return null }
    }) {

        let auth = getAuth(app)
        setPersistence(auth, browserSessionPersistence)
            .then(() => {
                createUserWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                        const user = userCredential.user
                        sendEmailVerification(user)
                        onSuccess()
                    })
                    .catch((error) => {
                        getFirebaseErrorMessage(error, onFailure)
                    })
            })
    }

    static signInWithEmailAndPassword({
        email,
        password,
        onSuccess = () => { return null },
        onFailure = () => { return null }
    }) {

        let auth = getAuth(app)
        setPersistence(auth, browserSessionPersistence)
            .then(() => {
                signInWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                        const user = userCredential.user
                        onSuccess()
                        window.location.reload()
                    })
                    .catch((error) => {
                        getFirebaseErrorMessage(error, onFailure)
                    })
            })

    }

    static sendPasswordResetEmail({
        email,
        onSuccess = () => { return null },
        onFailure = () => { return null }
    }) {

        let auth = getAuth(app)
        sendPasswordResetEmail(auth, email)
            .then(() => {
                onSuccess()
            })
            .catch((error) => {
                getFirebaseErrorMessage(error, onFailure)
            })
    }

    static deleteUser({
        onSuccess = () => { return null },
        onFailure = () => { return null }
    }) {

        let auth = getAuth(app)
        const user = auth.currentUser
        deleteUser(user).then(() => {
            onSuccess()
        }).catch((error) => {
            onFailure()
        })
    }

    static async logout() {
        const auth = getAuth(app)
        await signOut(auth)
        localStorage.clear()
        sessionStorage.clear()
        // window.location.reload()
    }
}

export { Auth }