
import NavBar from "components/navbar/profile/NavBar"
import ProfileInformation from 'pages/profile/ProfileInformation'


export default function ProfilePage() {
    return <>
    {/* <NavBar/> */}
    <ProfileInformation/>
    </>
}