import BaseAPI from 'api/baseAPI'

export class ConversationAPI {

    static createConversation({
        title,
        question,
        open_ended,
        tags,
        onSuccess = () => { return null },
        onFailure = () => { return null },
    }) {

        const conversation = { title, question, open_ended, tags }
        BaseAPI.call({
            type: 'create',
            path: 'conversations/conversations/',
            object: conversation,
            onSuccess,
            onFailure
        })
    }

    static getConversation({
        conversationId,
        onSuccess = () => { return null },
        onFailure = () => { return null },
    }) {

        BaseAPI.call({
            type: 'get',
            path: `conversations/conversations/${conversationId}`,
            onSuccess,
            onFailure
        })
    }

    static getAllConversation({
        searchText,
        page,
        onSuccess = () => { return null },
        onFailure = () => { return null },
    }) {

        BaseAPI.call({
            type: 'get',
            path: `conversations/conversations/?page=${page}`,
            object: { searchText },
            onSuccess,
            onFailure
        })

    }
}