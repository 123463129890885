
import { useState } from 'react'

import {
    Button,
    Stack,
    Typography,
    TextField,
    Autocomplete
} from '@mui/material'
import { ClaimAPI } from 'api/claims'
import ButtonWithAlert from 'components/views/ButtonWithAlert'

export default function CreateClaimForm({
    conversation,
    type,
    onClose,
    parent = null
}) {

    const [text, setText] = useState('')
    const [tags, setTags] = useState([])

    const disabled = (text === '')

    return <Stack
        spacing={3}
        sx={{ width: '50vw' }}>
        <Typography variant='h4'>
            Create a Claim
        </Typography>
        <TextField
            label='Claim'
            onChange={event => setText(event.target.value)}
            value={text}
        />
        <Autocomplete
            options={conversation?.tags}
            multiple
            value={tags}
            onChange={(event, value, reason, details) => setTags(value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Add Tags"
                    placeholder="Tags"
                />
            )}
        />
        <ButtonWithAlert
            variant='contained'
            disabled={disabled}
            apiCall={ClaimAPI.createClaim}
            apiArgs={{
                text,
                parent: parent?.id,
                conversationId: conversation.id,
                type,
                tags,
            }}
            onSuccess={(res) => setTimeout(() => onClose(res.data), 1000)}
            successMessage='The Claim has been created'
        >
            Create
        </ButtonWithAlert>
    </Stack>

}