import { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { CircularProgress, Grid } from '@mui/material'
import { ConversationAPI } from 'api/conversations'
import ConversationCard from 'components/conversations/ConversationCard'


export function ConversationScroller({ searchText, ...restProps }) {

    const [page, setPage] = useState(1)
    const [conversations, setConversations] = useState([])
    const [hasMore, setHasMore] = useState(true)

    const fetchData = () => {

        ConversationAPI.getAllConversation({
            searchText,
            page: page,
            onSuccess: res => {
                setHasMore(res.data.total_pages > page)
                setConversations([...conversations, ...res.data.results])
                setPage(page + 1)
            },
            onFailure: () => setHasMore(false)
        })
    }

    const conversationCards = <Grid container spacing={3} >
        {conversations.map((conversation) => {
            return <Grid item key={conversation.id} xs={12} sm={6} md={3} >
                <ConversationCard conversation={conversation} />
            </Grid>
        })}
    </Grid>

    return <InfiniteScroll
        {...restProps}
        style={{ width: '100%' }}
        pageStart={0}
        loadMore={fetchData}
        hasMore={hasMore}
        useWindow={false}
        loader={<CircularProgress key={0} />}
    >
        {conversationCards}
    </InfiniteScroll>
}