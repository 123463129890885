import { useState, useEffect } from 'react'
import {
    useParams,
    useOutletContext
} from "react-router-dom";
import {
    Stack,
    Typography,
    Box,
    CircularProgress
} from '@mui/material'
import { ClaimAPI } from 'api/claims'
import ClaimsTraverseView from 'pages/claims/ClaimsTraverseView'
import String from 'utils/strings'


export default function ClaimsView() {

    const { conversation } = useOutletContext()
    const [claims, setClaims] = useState([])
    const [conversationLoading, setConversationLoading] = useState(false)
    const [claimsLoading, setClaimsLoading] = useState(false)
    const { conversationId } = useParams()


    useEffect(() => {
        setClaimsLoading(true)
        ClaimAPI.getTreeClaims({
            conversationId: conversationId,
            onSuccess: (res) => {
                setClaimsLoading(false)
                setClaims(res.data.results)
            },
            onFailure: (res) => {
                setClaimsLoading(false)
            }
        })
    }, [])

    const modifyClaims = (newClaim, parent) => {

        const newClaims = [...claims]
        if (!parent) {
            newClaims.unshift(newClaim)
            setClaims(newClaims)
            return
        }

        const dfs = (rootClaims) => {
            for (var i = 0; i < rootClaims.length; i++) {
                if (rootClaims[i].id === parent.id) {
                    rootClaims[i].children.unshift(newClaim)
                    return
                } else {
                    dfs(rootClaims[i].children)
                }
            }
        }

        dfs(newClaims)
        setClaims(newClaims)
    }

    return <Box sx={{ padding: 3 }}>
        <Stack spacing={2} alignItems='center'>
            {conversationLoading ? <CircularProgress /> : <Typography variant='h5'>
                {String.getLinks(conversation?.question)}
            </Typography>}
            {claimsLoading ? <CircularProgress /> : <ClaimsTraverseView
                modifyClaims={modifyClaims}
                conversation={conversation}
                claims={claims} />}
        </Stack>
    </Box>
}