
import {
    Stack,
    Typography
} from '@mui/material'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'


export default function InactiveVoteView({ claim }) {

    return <Stack direction='row' spacing={1}>
        <ThumbUpAltIcon color='disabled'/>
        <Typography>
            {claim?.up_votes}
        </Typography>
        <ThumbDownAltIcon color='disabled'/>
        <Typography>
            {claim?.down_votes}
        </Typography>
    </Stack>
}