import { Navigate, useRoutes } from 'react-router-dom'
import PrivateRoute from 'route/PrivateRoute'
import GuestRoute from 'route/GuestRoute'
import LoginContainer from 'pages/login/LoginContainer'
import ConversationFinder from 'pages/finder/ConversationFinder'
import ClaimsView from 'pages/claims/ClaimsView'
import MainLayout from 'layout/MainLayout'
import VerifyEmailView from 'pages/login/VerifyEmailView'
import ConversationLayout from 'layout/ConversationLayout'
import Overview from 'pages/insight/overview/Overview'
import ProfilePage from 'pages/profile/ProfilePage'
import ProfileOwnerRoute from './ProfileOwnerRoute'


export default function Router() {
    return useRoutes([
        {
            path: 'login',
            element: (
                <GuestRoute>
                    <LoginContainer />
                </GuestRoute>
            ),
        },
        {
            path: 'verify-email',
            element: (
                <GuestRoute>
                    <VerifyEmailView />
                </GuestRoute>
            ),
        },
        {
            path: '/',
            element: (
                <PrivateRoute>
                    <MainLayout />
                </PrivateRoute>
            ),
            children: [
                { element: <Navigate to='/conversations' replace />, index: true },
                { path: 'conversations', element: <ConversationFinder /> },
            ]
        },
        {
            path: '/profile',
            element: (
                <PrivateRoute>
                    <MainLayout />
                </PrivateRoute>
            ),
            children: [
                {
                    path: ':username',
                    element: <ProfileOwnerRoute>
                        <ProfilePage />
                    </ProfileOwnerRoute>
                },
            ]
        },
        {
            element: (
                <PrivateRoute>
                    <ConversationLayout />
                </PrivateRoute>
            ),
            children: [
                { path: 'conversation/:conversationId', element: <ClaimsView /> },
                {
                    path: 'insight/:conversationId',
                    children: [
                        { element: <Navigate to='overview/' replace />, index: true },
                        { path: 'overview/:tag', element: <Overview /> },
                        { path: 'overview/', element: <Overview /> },
                    ]
                },

            ]
        }
    ])
}