
import { useState } from 'react'
import { Paper, Typography, Stack } from '@mui/material'
import TagsView from './TagsView'
import VoteView from './VoteView'
import { styled } from '@mui/material/styles'
import String from 'utils/strings'


const PaperColor = styled(Paper, {
    shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
    "&:hover": {
        // borderColor: "blue !important"
        border: `1px solid ${color}`
    }
}))

function getColor(claim) {
    if (!claim) return '#05e3e3'

    switch (claim.type) {
        case 'CON':
            return '#fa1b1b'
        case 'PRO':
            return '#0ccc13'
        default:
            return '#05e3e3'
    }
}

export default function BaseClaimView({
    claim,
    conversation,
    isTop = false,
    onClick = null,
    ...restProps
}) {

    const [elevation, setElevation] = useState(0)

    const onMouseOver = () => setElevation(20)
    const onMouseOut = () => setElevation(0)

    const color = getColor(claim)
    const text = claim ? claim?.text : conversation?.question
    const tags = claim ? claim?.tags : conversation?.tags
    const variant = isTop ? 'h6' : 'body1'

    return <PaperColor
        {...restProps}
        color={color}
        elevation={elevation}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onClick}
        sx={{ pt: 3, pl: 3, pr: 3, pb: 1, borderRadius: 5, width: '100%' }}>
        <Stack
            spacing={2}
            justifyContent='space-evenly'
            alignItems='flex-start'
            sx={{ width: '100%' }}
        >
            <Typography variant={variant}>
                {String.getLinks(text)}
            </Typography>
            <Stack
                sx={{ width: '100%' }}
                justifyContent='space-evenly'
                alignItems='flex-end'>
                <TagsView tags={tags} />
                {claim && <VoteView claim={claim} />}
            </Stack>
        </Stack>
    </PaperColor>

}