import Modal from 'components/views/Modal'
import CreateClaimForm from 'pages/claims/CreateClaimForm'


export default function CreateClaimFormContainer({ conversation, type, parent, ...restProps }) {
    return <Modal
        open={restProps.open}
        onClose={restProps.onClose}>
        <CreateClaimForm
            conversation={conversation}
            type={type}
            onClose={restProps.onClose}
            parent={parent} />
    </Modal>
}