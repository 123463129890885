import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { IconButton, Typography } from "@mui/material"
import { useNavigate } from 'react-router-dom'


export default function LogoutButton() {

    const navigate = useNavigate()

    return <IconButton
        size='large'
        edge='end'
        onClick={() => navigate('/conversations/')}
        color='inherit'>
        <ArrowBackIosNewIcon />
        <Typography variant='subtitle1' sx={{ mr: 1 }}>
            Back
        </Typography>
    </IconButton>
}