import { useState } from 'react'
import { Stack, Button, Grid } from '@mui/material'
import CreateConversationFormContainer from 'pages/finder/CreateConversationFormContainer'
import SearchBar from 'components/views/SearchBar'
import { ConversationScroller } from 'pages/finder/ConversationScroller'


export default function ConversationFinder() {

    const [open, setOpen] = useState(false)
    const [searchText, setSearchText] = useState('')

    const onClose = () => setOpen(false)
    const onClick = () => setOpen(true)

    return <>
        <Stack
            spacing={2}
            alignItems='center'>
            <Grid
                container
                direction='row'
                spacing={2}
                justifyContent='space-between'
                alignItems='center'>
                <Grid item xs={4} />
                <Grid item xs={4} >
                    <SearchBar searchText={searchText} setSearchText={setSearchText} />
                </Grid>
                <Grid item xs={4} >
                    <Button variant='outlined' onClick={onClick}>
                        Create Conversation
                    </Button>
                </Grid>
            </Grid>
            <ConversationScroller searchText={searchText} key={searchText} />
        </Stack>
        {open && <CreateConversationFormContainer open={open} onClose={onClose} />}
    </>
}