import axios from 'axios'
import { Auth } from 'components/login/firebaseAuth'


const defaultErrorMessage = 'Oops something went wrong!'

export default class BaseAPI {

    static getBaseAPI() {

        switch (window.location.hostname) {
            case 'insight.standpointapp.com':
                return 'https://api.insight.standpointapp.com/'
            case 'staging.insight.standpointapp.com':
                return 'https://api.staging.insight.standpointapp.com/'
            case 'localhost':
                return 'http://localhost:8000/'
                // return 'https://api.staging.insight.standpointapp.com/'
        }
    }

    static call({
        type,
        path,
        object = null,
        onSuccess = () => { return null },
        onFailure = () => { return null },
    }) {

        const fullPath = BaseAPI.getBaseAPI() + path

        switch (type) {
            case 'create':
                Auth.AuthAPICall(token => {
                    axios.post(fullPath,
                        object,
                        {
                            headers: { 'Authorization': `Bearer ${token}` }
                        }
                    ).then(
                        onSuccess, 
                        (err) => onFailure(defaultErrorMessage)
                    )
                })
                break
            case 'get':

                Auth.AuthAPICall(token => {
                    const params = object ? {
                        headers: { 'Authorization': `Bearer ${token}` },
                        params: object
                    } : {
                        headers: { 'Authorization': `Bearer ${token}` },
                    }
                    axios.get(fullPath, params).then(onSuccess, onFailure)
                })
                break
            case 'put':
                Auth.AuthAPICall(token => {
                    axios.put(fullPath, object,
                        {
                            headers: { 'Authorization': `Bearer ${token}` }
                        }
                    ).then(onSuccess, onFailure)
                })
                break
            case 'patch':
                Auth.AuthAPICall(token => {
                    axios.patch(fullPath, object,
                        {
                            headers: { 'Authorization': `Bearer ${token}` }
                        }
                    ).then(onSuccess, onFailure)
                })
                break
            case 'delete':
                    Auth.AuthAPICall(token => {
                        axios.delete(fullPath, 
                            {
                                headers: { 'Authorization': `Bearer ${token}` }
                            }
                        ).then(onSuccess, onFailure)
                    })
                    break
            default:
                throw new TypeError('Wrong call api type')

        }

    }
}