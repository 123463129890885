import BaseAPI from 'api/baseAPI'

export class ClaimAPI {

    static createClaim({
        text,
        parent=null,
        conversationId,
        type,
        tags,
        onSuccess = () => { return null },
        onFailure = () => { return null },
    }) {

        const claim = { text, parent, conversation: conversationId, tags, type }
        BaseAPI.call({
            type: 'create', 
            path: 'conversations/claims/', 
            object: claim,
            onSuccess,
            onFailure
        })
    }

    static getTreeClaims({
        conversationId,
        onSuccess = () => { return null },
        onFailure = () => { return null },
    }) {

        BaseAPI.call({
            type: 'get', 
            path: 'conversations/claims/', 
            object: { conversationId },
            onSuccess,
            onFailure
        })
    }

    static getTopClaims({
        conversationId,
        topRelation,
        tag,
        claimId,
        onSuccess = () => { return null },
        onFailure = () => { return null },
    }) {
        const object = {
            conversationId,
            topRelation,
            tag,
            claimId,
        }
        BaseAPI.call({
            type: 'get', 
            path: 'conversations/top-claims/', 
            object: object,
            onSuccess,
            onFailure
        })
    }
}