
import { useState, useEffect } from 'react'
import BaseClaimView from 'components/conversations/BaseClaimView'
import { Stack, Grid, Paper, Typography, IconButton, Slide } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Colors from 'components/visualizations/colors'
import CreateClaimFormContainer from 'pages/claims/CreateClaimFormContainer'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'


function TopClaimView({ conversation, claim }) {
    return <BaseClaimView
        conversation={conversation}
        claim={claim}
        isTop={true} />
}

function AddClaimView({ type, conversation, topClaim, modifyClaims }) {

    const [open, setOpen] = useState(false)

    var color = null
    var text = null

    switch (type) {
        case 'CON':
            color = Colors.CON
            text = 'Cons'
            break
        case 'PRO':
            color = Colors.PRO
            text = 'Pros'
            break
        case 'ANS':
            color = Colors.ANS
            text = 'Answers'
            break
    }

    const onClick = () => {
        setOpen(true)
    }

    const close = (claim) => {
        setOpen(false)
        if (!claim) return
        modifyClaims(claim, topClaim)
    }

    return <Paper elevation={0} sx={{ width: '100%', padding: 2 }}>
        <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'>
            <Typography variant='h6' color={color}>
                <b>{text}</b>
            </Typography>
            <IconButton onClick={onClick}>
                <AddCircleIcon sx={{ fontSize: 40, color: color }} />
            </IconButton>
            {open && <CreateClaimFormContainer
                open={open}
                onClose={close}
                conversation={conversation}
                type={type}
                parent={topClaim}
            />}
        </Stack>
    </Paper>
}


function UnaryView({
    claims,
    conversation,
    topClaim,
    modifyClaims,
    onDescentClick
}) {

    return <Stack spacing={1} sx={{width:'100%'}}>
        <AddClaimView type='ANS'
            modifyClaims={modifyClaims}
            topClaim={topClaim}
            conversation={conversation} />
        {claims.map(claim => {
            return <BaseClaimView
                onClick={() => onDescentClick(claim)}
                key={claim.id}
                conversation={conversation}
                claim={claim} />
        })}
    </Stack>
}


function BinaryView({
    proClaims,
    conClaims,
    conversation,
    topClaim,
    modifyClaims,
    onDescentClick
}) {

    return <Grid container spacing={1}>
        <Grid item xs={6}>
            <Stack spacing={1} >
                <AddClaimView
                    type='PRO'
                    modifyClaims={modifyClaims}
                    conversation={conversation}
                    topClaim={topClaim} />
                {proClaims.map(claim => {
                    return <BaseClaimView
                        key={claim.id}
                        conversation={conversation}
                        onClick={() => onDescentClick(claim)}
                        claim={claim} />
                })}
            </Stack>
        </Grid>
        <Grid item xs={6}>
            <Stack spacing={1} >
                <AddClaimView
                    type='CON'
                    modifyClaims={modifyClaims}
                    conversation={conversation}
                    topClaim={topClaim} />
                {conClaims.map(claim => {
                    return <BaseClaimView
                        key={claim.id}
                        conversation={conversation}
                        onClick={() => onDescentClick(claim)}
                        claim={claim} />
                })}
            </Stack>
        </Grid>
    </Grid>
}

function MultipleClaimsView({
    claims,
    conversation,
    openEnded,
    topClaim,
    modifyClaims,
    onDescentClick
}) {

    if (openEnded) {
        return <UnaryView
            onDescentClick={onDescentClick}
            modifyClaims={modifyClaims}
            claims={claims}
            topClaim={topClaim}
            conversation={conversation} />
    } else {
        const proClaims = claims.filter(claim => claim.type === 'PRO')
        const conClaims = claims.filter(claim => claim.type === 'CON')
        return <BinaryView
            onDescentClick={onDescentClick}
            modifyClaims={modifyClaims}
            conversation={conversation}
            topClaim={topClaim}
            proClaims={proClaims}
            conClaims={conClaims} />
    }
}


export default function ClaimsTraverseView({
    conversation,
    claims,
    modifyClaims,
    topClaim = null,
}) {

    const [curentClaims, setCurrentClaims] = useState([])
    const [currentTopClaim, setCurrentTopClaim] = useState(null)
    const [openEnded, setOpenEnded] = useState(false)
    const [randomKey, setRandomKey] = useState(Math.random())
    const [direction, setDirection] = useState('up')
    const [slideIn, setSlideIn] = useState(true)

    const slideTime = 250

    useEffect(() => {
        setCurrentClaims(claims)
        setCurrentTopClaim(topClaim)
        setOpenEnded(conversation?.open_ended)
    }, [claims, topClaim, conversation])

    const slideUp = () => {
        setDirection('down')
        setSlideIn(false)
        setTimeout(() => {
            setRandomKey(Math.random())
            setDirection('up')
            setSlideIn(true)
        }, slideTime / 2)
    }

    const slideDown = () => {
        setDirection('up')
        setSlideIn(false)
        setTimeout(() => {
            setRandomKey(Math.random())
            setDirection('down')
            setSlideIn(true)
        }, slideTime / 2)
    }

    const onDescentClick = (claim) => {

        slideUp()
        setCurrentClaims(claim?.children)
        setCurrentTopClaim(claim)
        setOpenEnded(false)
    }

    const onAscentClick = () => {
        if (!currentTopClaim) return

        slideDown()

        if (claims.map(c => c.id).includes(currentTopClaim.id)) {
            setCurrentClaims([...claims])
            setCurrentTopClaim(null)
            setOpenEnded(conversation?.open_ended)
            return
        }

        const dfs = (rootClaims) => {
            var val = null
            for (let cl of rootClaims) {
                if (cl.children.map(c => c.id).includes(currentTopClaim.id)) {
                    return [cl.children, cl]
                } else {
                    val = dfs(cl.children)
                    if (val) return val
                }
            }
        }
        const [children, parent] = dfs(claims)
        setCurrentClaims([...children])
        setCurrentTopClaim(parent)
    }

    return <Slide
        key={randomKey}
        direction={direction}
        in={slideIn}
        mountOnEnter
        timeout={slideTime}
        unmountOnExit>
        <Stack width='80%' spacing={1} alignItems='center'>
            <IconButton onClick={onAscentClick} color='primary'>
                <ArrowCircleUpIcon sx={{ fontSize: 40 }} />
            </IconButton>
            <TopClaimView
                conversation={conversation}
                claim={currentTopClaim} />
            <MultipleClaimsView
                claims={curentClaims}
                onDescentClick={onDescentClick}
                modifyClaims={modifyClaims}
                conversation={conversation}
                topClaim={currentTopClaim}
                openEnded={openEnded} />
        </Stack >
    </Slide>



}