
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Auth } from 'components/login/firebaseAuth'
import CircularProgress from '@mui/material/CircularProgress'
import Constant from 'route/url'



export default function PrivateRoute({ children, to }) {
  const [user, loading, error] = useAuthState(Auth.getAuth())
  let location = useLocation()

  if (loading) {
    return <CircularProgress />
  } else if (!user) {
    return <Navigate to={Constant.URL.LOGIN} state={{ from: location }} replace />
  } else if (user && !user.emailVerified) {
    return <Navigate to={Constant.URL.VERIFY_EMAIL} state={{ from: location }} replace />
  } else if (to) {
    return <Navigate to={to} state={{ from: location }} replace />
  }
  return children
}


