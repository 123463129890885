

import Login from 'pages/login/Login'
import {
    Grid,
    Stack
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import loginlanding from 'images/landing.svg'


function LoginMobile() {

    return <Stack
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%", pb: 20}}>
        <img src={loginlanding} alt='landing' style={{ width: 250 }} />
        <Login />
    </Stack>

}

function LoginDesktop() {
    return  <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%", pb:20 }}>

        <Grid item md={6} >
            <img src={loginlanding} alt='landing' style={{ width: 480 }} />
        </Grid>
        <Grid item md={6}>
            <Login />
        </Grid>
    </Grid>
}


export default function LoginContainer() {

    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'))

    return mobile ? <LoginMobile /> : <LoginDesktop />

}