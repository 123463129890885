import { useState } from 'react'
import {
    Stack,
    Typography,
    TextField,
    Autocomplete,
    Switch,
    FormGroup,
    FormControlLabel,
    Button
} from '@mui/material'
import { ConversationAPI } from 'api/conversations'
import { useNavigate } from 'react-router-dom'
import ButtonWithAlert from 'components/views/ButtonWithAlert'

export default function CreateConversationForm() {

    const [title, setTitle] = useState('')
    const [question, setQuestion] = useState('')
    const [openEnded, setOpenEnded] = useState(false)
    const [tags, setTags] = useState([])
    const navigate = useNavigate()

    const disabled = (title === '') || (question === '')

    return <Stack
        spacing={3}
        sx={{ width: '50vw', height: '50vh' }}>
        <Typography variant='h4'>
            Create a Conversation
        </Typography>
        <TextField
            label='Title'
            onChange={event => setTitle(event.target.value)}
            value={title}
        // error={errorFirstname}
        // helperText={errorFirstnameText}
        />
        <TextField
            label='Question'
            multiline
            rows={3}
            onChange={event => setQuestion(event.target.value)}
            value={question}
        // error={errorFirstname}
        // helperText={errorFirstnameText}
        />
        <FormGroup>
            <FormControlLabel control={
                <Switch
                    checked={openEnded}
                    onChange={event => setOpenEnded(event.target.checked)} />
            }
                label="Open Ended" />
        </FormGroup>
        <Autocomplete
            freeSolo={true}
            options={[]}
            multiple
            value={tags}
            onChange={(event, value, reason, details) => setTags(value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Add Tags"
                    placeholder="Tags"
                />
            )}
        />
        <ButtonWithAlert
            variant='contained'
            disabled={disabled}
            apiCall={ConversationAPI.createConversation}
            apiArgs={{
                title,
                question,
                open_ended: openEnded,
                tags,
            }}
            onSuccess={(res) => navigate(`/conversation/${res.data.id}`)}
            successMessage='The Conversation has been created'
        >
            Create
        </ButtonWithAlert>
    </Stack>
}