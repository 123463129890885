export default class Constant {

    static URL = class {
        static LOGIN = '/login'
        static DASHBOARD = '/dashboard'
        static FEED = '/feed'
        static INSIGHT = '/insight'
        static CONVERSATIONS = '/conversations'
        static CLAIMS = '/claims'
        static VERIFY_EMAIL = '/verify-email'
    }
}