import { useState } from 'react'
import { Auth } from 'components/login/firebaseAuth'
import {
    TextField,
    Stack,
    Button,
} from '@mui/material'
import ResetPasswordView from 'pages/login/ResetPasswordView'
import ButtonWithAlert from 'components/views/ButtonWithAlert'


function Login() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [openReset, setOpenReset] = useState(false)

    const closeReset = () => {
        setOpenReset(false)
    }

    return (
        <Stack spacing={1} sx={{ maxWidth: 400, width: "100%" }}>
            <TextField
                type='email'
                label='Email Address'
                autoComplete='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
                type='password'
                label='Password'
                autoComplete='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Stack spacing={1} direction='row' sx={{ display: 'flex' }}>
                <ButtonWithAlert
                    sx={{ flex: 1 }}
                    variant='contained'
                    color='secondary'
                    apiCall={Auth.createUserWithEmailAndPassword}
                    apiArgs={{ email, password }}
                    successMessage='The User has been Created'
                >
                    Create User
                </ButtonWithAlert>
                <ButtonWithAlert
                    sx={{ flex: 1 }}
                    variant='contained'
                    apiCall={Auth.signInWithEmailAndPassword}
                    apiArgs={{ email, password }}
                    successMessage='You are now logged in'
                >
                    Login
                </ButtonWithAlert>
            </Stack>
            <Button onClick={() => setOpenReset(true)}>
                Forgot Password?
            </Button>
            {openReset && <ResetPasswordView open={openReset} onClose={closeReset} />}
        </Stack>
    )
}
export default Login