
import Modal from 'components/views/Modal'
import CreateConversationForm from 'pages/finder/CreateConversationForm'


export default function CreateConversationFormContainer({type, parent, ...props}) {

    return <Modal open={props.open} onClose={props.onClose}>
        <CreateConversationForm />
    </Modal>
}