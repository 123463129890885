
import { IconButton, Tooltip } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Auth } from 'components/login/firebaseAuth'
import CircularProgress from '@mui/material/CircularProgress'
import Constant from 'route/url'
import { useNavigate } from 'react-router-dom'


export default function ProfileButton() {

    const [user, loading, error] = useAuthState(Auth.getAuth())
    const navigate = useNavigate()

    const onClick = () => {
        if (!user) return
        navigate(`/profile/${user.uid}`)
    }

    return <Tooltip title="Profile">
        <IconButton color='inherit' disabled={loading} onClick={onClick}>
            <AccountCircleIcon color='warning' sx={{ width: 40, height: 40 }} />
        </IconButton>
    </Tooltip>
} 