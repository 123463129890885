
import { Stack, Typography } from '@mui/material'

export default function TagsView({ tags, ...restProps }){

    return <Stack direction='row' spacing={1}>
        {tags?.map( tag => {
            return <Typography 
            variant='caption'
            key={tag} 
            sx={{border:'1px solid grey', borderRadius: 200, padding:1, color: 'text.secondary'}}>
                {tag}
            </Typography>
        })}
    </Stack>

}